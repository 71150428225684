import { post, put } from "../../helpers/Requests";
import { CloudDashboard, CloudReport, CloudUser, MailBundle, MailBundleWithRelations, newMailBundle, ReportingDashboard, InsightView, Consultant } from "../../types/cloud_types";
import { Plan} from "../../types/reporting_types";
import { ServerData } from "../../types/types";
import { Dispatch, Fragment, SetStateAction, useEffect, useMemo, useState } from "react";
import { MenuItem } from "@headlessui/react";
import Button from "../Button";
import CustomInput from "../CustomInput";
import MultiSelect from "../MultiSelect";
import { FaCalculator, FaEnvelope, FaFile, FaTable, FaTh, FaWindowMaximize } from "react-icons/fa";
import CustomSwitch from "../CustomSwitch";
import { MdClose, MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";
import { isReportingServiceUser } from "../../helpers/permissions";
import { RiDashboard2Fill } from "react-icons/ri";
import { GoGraph } from "react-icons/go";
import { IoDocumentsOutline, IoDocumentOutline } from "react-icons/io5";
import MenuDropdown from "../MenuDropdown";
import { HiDotsCircleHorizontal } from "react-icons/hi";
import { useAppContext } from "../../App";
import ReportingModal from "./ReportingModal";
import AlertBanner from "../Alerts/AlertBanner";

interface AddMailBundleProps {
    open: boolean;
    onClose: () => any;
    update: () => any;
    cloudDashboards: CloudDashboard[];
    cloudReports: CloudReport[];
    users: CloudUser[];
    consultants: Consultant[];
    orgId: number;
    plans: Plan[];
    server: ServerData | undefined;
    selectedMailBundle?: MailBundleWithRelations
    use_access_control: boolean
    location_enabled: boolean
    initialPage: 0 | 1 | 2 | 3
}

function AddMailBundle(props:AddMailBundleProps) {
    const {user} = useAppContext()
    const [mailBundle, setMailBundle] = useState<MailBundleWithRelations>(props.selectedMailBundle ? props.selectedMailBundle : newMailBundle(props.orgId))
    
    const [recipientIds, setRecipientIds] = useState<number[]>([])
    const [reportIds, setReportIds] = useState<number[]>([])
    const [insightViews, setInsightViews] = useState<InsightView[]>([])

    const [selectedPlan, setSelectedPlan] = useState<Plan|null>(props.selectedMailBundle ? props.plans.find(p => p.id === props.selectedMailBundle?.plan_id) ?? null : null)
    const [page, setPage] = useState<number>(!!props.selectedMailBundle ? props.initialPage : 0)
    
    const [selectedDashboards, setSelectedDashboards] = useState<ReportingDashboard[]>([]) 

    const onCloseLocal = () => {
        setMailBundle(newMailBundle(props.orgId));
        props.onClose();
    }

    const getMailBundleRelations = async (mID:number) => {
        if(!mID || !props.selectedMailBundle) return
        setRecipientIds(structuredClone(props.selectedMailBundle.user_ids) ?? [])
        setSelectedDashboards(structuredClone(props.selectedMailBundle.dashboards) ?? [])
        setReportIds(structuredClone(props.selectedMailBundle.report_ids) ?? [])
        setInsightViews(structuredClone(props.selectedMailBundle.insight_views) ?? [])
    }

    const updateMailBundleState = (key: string, value: any) => {
        setMailBundle({ ...mailBundle, [key]: value })
    }

    useEffect(() => {
        if(selectedPlan){
            setMailBundle({...mailBundle, plan: selectedPlan.interval, plan_id: selectedPlan.id})
        }
    }, [selectedPlan])

    useEffect(() => {
        if(props.selectedMailBundle){
            void getMailBundleRelations(props.selectedMailBundle.id)
        }
    }, [props.selectedMailBundle])
    
    const saveMailBundle = (activate: boolean, send?: boolean) => {
        if(!validateMailBundle() || !user) return;
        const mailBundleWithRelations : MailBundleWithRelations = {
            ...mailBundle,
            owner_id: user.id,
            user_ids: recipientIds,
            report_ids: reportIds,
            insight_views: insightViews,
            dashboards: selectedDashboards
        }
        if(activate){
            mailBundleWithRelations.active = true
        }
        if(props.selectedMailBundle){
            put(`cloud/${props.server?.cloud}/mailbundle/${props.selectedMailBundle.id}${send ? "/send" : ""}`, mailBundleWithRelations).then(res => {
                onCloseLocal();
                props.update();
            }).catch(err => console.log(err))
        } else {
            post(`cloud/${props.server?.cloud}/mailbundle${send ? "/send" : ""}`, mailBundleWithRelations).then(res => {
                onCloseLocal();
                props.update();
            }).catch(err => console.log(err))
        }
        
    }

    const canGoToPage2 = () => {
        return mailBundle.name && (selectedDashboards.length > 0 || reportIds.length > 0 || insightViews.length > 0);
    }
        
    const canGoToPage3 = () => {
        return canGoToPage2() && selectedPlan;
    }

    const canGoToPage4 = () => {
        return canGoToPage3() && recipientIds.length > 0;
    }
            
    const canGoToNextPage = () => {
        return (page === 0 && canGoToPage2()) || (page === 1 && canGoToPage3()) || (page === 2 && canGoToPage4());
    }

    const nextPage = () => {
        if(page < 3){
            setPage(page+1)
        }
    }

    const prevPage = () => {
        if(page > 0){
            setPage(page-1)
        }
    }

    const validateMailBundle = () => {
        const hasName = !!mailBundle.name;
        const hasPlan = !!mailBundle.plan && !!mailBundle.plan_id;
        const hasReceivers = recipientIds.length > 0;
        const hasContent = selectedDashboards.length > 0 || reportIds.length > 0 || insightViews.length > 0;

        return hasName && hasPlan && hasReceivers && hasContent;
    }
    
    return (
        <ReportingModal
            open={props.open}
            className="flex flex-col justify-between gap-2 w-[700px] h-[900px]"
        >
            <div className="flex flex-col gap-2">
                <div className="border-b p-2 text-xl font-semibold flex items-center gap-1 whitespace-nowrap">
                    {page === 0 && <span>{props.selectedMailBundle ? "Update mail bundle" : "Create mail bundle"}</span>}
                    {page === 2 && <span>Schedule</span>}
                    {page === 3 && <span>Select recipients for</span>}
                    {page === 1 && <span>PDF Settings</span>}
                    <div className="text-gray-300 overflow-hidden text-ellipsis">{mailBundle.name}</div>
                </div>
                
                {props.selectedMailBundle && <div className="w-full flex items-start">
                    <div 
                        onClick={() => setPage(0)}
                        className={`${page === 0 ? "text-tangerine-900 border-b-tangerine-900" : "text-gray-400"} cursor-default font-bold border-b-2 px-8 pb-2 select-none`}
                    >Setup</div>
                    <div 
                        onClick={() => {if(canGoToPage2()) setPage(1)}}
                        className={`${page === 1 ? "text-tangerine-900 border-b-tangerine-900" : "text-gray-400"} cursor-default font-bold border-b-2 px-8 pb-2 select-none`}
                    >Schedule</div>
                    <div 
                        onClick={() => {if(canGoToPage3()) setPage(2)}}
                        className={`${page === 2 ? "text-tangerine-900 border-b-tangerine-900" : "text-gray-400"} cursor-default font-bold border-b-2 px-8 pb-2 select-none`}
                    >Recipients</div>
                    <div 
                        onClick={() => {if(canGoToPage4()) setPage(3)}}
                        className={`${page === 3 ? "text-tangerine-900 border-b-tangerine-900" : "text-gray-400"} cursor-default font-bold border-b-2 px-8 pb-2 select-none`}
                    >PDF Settings</div>
                </div>}
                {page === 0 && <MailBundleSetup
                    mailBundle={mailBundle}
                    updateMailBundleState={updateMailBundleState}
                    cloudDashboards={props.cloudDashboards}
                    cloudReports={props.cloudReports}
                    selectedDashboards={selectedDashboards}
                    setSelectedDashboards={setSelectedDashboards}
                    insightViews={insightViews}
                    setInsightViews={setInsightViews}
                    reportIds={reportIds}
                    setReportIds={setReportIds}
                    />}
                {page === 1 && <ScheduleSelect
                    mailBundle={mailBundle}
                    plans={props.plans}
                    selectedPlan={selectedPlan}
                    setSelectedPlan={setSelectedPlan}
                    />}
                {page === 2 && <RecipientSelect
                    mailBundle={mailBundle}
                    users={props.users}
                    consultants={props.consultants}
                    selectedBuckets={
                        props.cloudReports
                            .filter(r => [...reportIds, ...insightViews.map(t => t.report_id)].includes(r.report_id ?? -1))
                            .map(r => r.bucket)
                    }
                    use_access_control={props.use_access_control}
                    recipientIds={recipientIds}
                    setRecipientIds={setRecipientIds}
                    updateMailBundleState={updateMailBundleState}
                    location_enabled={props.location_enabled}
                />}
                {page === 3 && <PdfOptions
                    mailBundle={mailBundle}
                    updateMailBundleState={updateMailBundleState}
                />}
            </div>
            <div className="flex flex-col bg-white border-t min-h-[100px]">
                {!props.selectedMailBundle && <div className="w-full flex gap-1 items-center justify-center p-2 mt-2">
                    <div 
                        onClick={() => setPage(0)}
                        className={`${page === 0 ? "bg-tangerine-900" : "bg-tangerine-200 cursor-pointer hover:bg-tangerine-900 transition-colors"} w-4 h-4 rounded-full`}
                        ></div>
                    <div 
                        onClick={() => {if(canGoToPage2()) setPage(1)}}
                        className={`${page === 1 ? "bg-tangerine-900" : "bg-tangerine-200"} ${canGoToPage2() ? "cursor-pointer hover:bg-tangerine-900 transition-colors" : ""} w-4 h-4 rounded-full`}
                    ></div>
                    <div 
                        onClick={() => {if(canGoToPage3()) setPage(2)}}
                        className={`${page === 2 ? "bg-tangerine-900" : "bg-tangerine-200"} ${canGoToPage3() ? "cursor-pointer hover:bg-tangerine-900 transition-colors" : ""} w-4 h-4 rounded-full`}
                        ></div>
                    <div 
                        onClick={() => {if(canGoToPage4()) setPage(3)}}
                        className={`${page === 3 ? "bg-tangerine-900" : "bg-tangerine-200"} ${canGoToPage4() ? "cursor-pointer hover:bg-tangerine-900 transition-colors" : ""} w-4 h-4 rounded-full`}
                        ></div>
                </div>}

                <div className={`${props.selectedMailBundle ? "mt-4" : ""} flex gap-2 justify-end items-center w-full`}>
                    <Button text="Close" color="default" onClick={onCloseLocal} />
                    {page > 0 && <Button text={<span className="flex items-center"><MdOutlineKeyboardDoubleArrowLeft/> Previous</span>} color="secondary" onClick={prevPage} />}
                    {page < 3 && <Button text={<span className="flex items-center">Next <MdOutlineKeyboardDoubleArrowRight/></span>} color={!!props.selectedMailBundle ? "secondary" : "primary"} onClick={nextPage} disabled={!canGoToNextPage()}/> }
                    {((page === 3 && !props.selectedMailBundle) || (!!props.selectedMailBundle && !props.selectedMailBundle.active)) && <SaveButton activateOnSave={true} addNewMailBundle={saveMailBundle} validateMailBundle={validateMailBundle} />}
                    {(page === 3 || !!props.selectedMailBundle) && <SaveButton activateOnSave={false} addNewMailBundle={saveMailBundle} validateMailBundle={validateMailBundle} />}
                </div>
            </div>
        </ReportingModal>
    );
}

interface MailBundleSetupProps {
    mailBundle: MailBundleWithRelations;
    updateMailBundleState: (key:string, value:any) => void;
    cloudDashboards: CloudDashboard[];
    cloudReports: CloudReport[];
    selectedDashboards: ReportingDashboard[];
    setSelectedDashboards: Dispatch<SetStateAction<ReportingDashboard[]>>
    reportIds: number[];
    setReportIds: Dispatch<SetStateAction<number[]>>
    insightViews: InsightView[];
    setInsightViews: Dispatch<SetStateAction<InsightView[]>>
}

function MailBundleSetup(props:MailBundleSetupProps){
    const [tmpInsightView, setTmpInsightView] = useState<InsightView>()
    const [insightViewUrl, setInsightViewUrl] = useState<string>("")

    const dashboardsChanged = useMemo(() => {
        if(props.mailBundle.dashboards.length !== props.selectedDashboards.length) return true
        for(const d of props.selectedDashboards){
            const id = d.dashboard_id
            const table = d.include_table
            const widgets = d.include_widgets
            if(!props.mailBundle.dashboards.some(db => db.dashboard_id === id && db.include_table === table && db.include_widgets === widgets)){
                return true
            }
        }
        return false;
    }, [props.mailBundle, props.selectedDashboards])
    
    const reportsChanged = useMemo(() => {
        if(props.mailBundle.report_ids.length !== props.reportIds.length) return true
        return !props.mailBundle.report_ids.every(id => props.reportIds.includes(id))
    }, [props.mailBundle, props.reportIds])
    
    const insightViewsChanged = useMemo(() => {
        if(props.mailBundle.insight_views.length !== props.insightViews.length) return true
        for(const iv of props.insightViews){
            const id = iv.report_id
            const hash = iv.hash
            const type = iv.type
            if(!props.mailBundle.insight_views.some(i => i.report_id === id && i.hash === hash && i.type === type)){
                return true
            }
        }
        return false;
    }, [props.mailBundle, props.insightViews])

    const getInsightViewObjFromURL = (url:string) : InsightView | undefined => {
        const reportIdPattern = /\/report\/(\d+)\//
        const reportIdMatch = url.match(reportIdPattern)
        
        const trendHashPattern = /trend\/([^?]+)/
        const matrixHashPattern = /overview\/([^?]+)/
        const kpiHashPattern = /kpis\/([^?]+)/
        const sumHashPattern = /sum\/([^?]+)/
        const profitHashPattern = /profit\/([^?]+)/
        
        const trendMatch = url.match(trendHashPattern)
        const matrixMatch = url.match(matrixHashPattern)
        const kpiMatch = url.match(kpiHashPattern)
        const sumMatch = url.match(sumHashPattern)
        const profitMatch = url.match(profitHashPattern)
        
        if(!reportIdMatch){
            return
        }
        const reportId = Number(reportIdMatch[1]);
        let type: "trend" | "matrix" | "kpi" | "sum" | "profit"
        let hash: string;
        if(!!trendMatch){   
            hash = trendMatch[1]
            type = "trend"
        }else if(!!matrixMatch){
            hash = matrixMatch[1]
            type = "matrix"
        } else if(!!kpiMatch){
            hash = kpiMatch[1]
            type = "kpi"
        } else if(!!sumMatch){
            hash = sumMatch[1]
            type = "sum"
        } else if(!!profitMatch){
            hash = profitMatch[1]
            type = "profit"
        } else {
            return;
        }
        return {
            report_id: reportId,
            hash,
            type
        }
    }

    useEffect(() => {
        const insightViewObj = getInsightViewObjFromURL(insightViewUrl);
        if(insightViewObj){
            setTmpInsightView(insightViewObj)
        } else {
            setTmpInsightView(undefined)
        }
    }, [insightViewUrl])

    const toggleDashboardSetting = (dID: number, setting: "include_table" | "include_widgets") => {
        const dashboard = props.selectedDashboards.find(d => d.dashboard_id === dID);
        if (!dashboard) return;
        dashboard[setting] = !dashboard[setting]

        if(!dashboard.include_table && !dashboard.include_widgets){
            return
        }

        props.setSelectedDashboards([
            ...props.selectedDashboards.map(d => d.dashboard_id === dID ? dashboard : d)
        ])
    }
    
    return (
        <>
            <CustomInput 
                onChange={e => props.updateMailBundleState("name", e.target.value)} 
                value={props.mailBundle.name} 
                label="Bundle Name"
                className="mb-4 w-96"
            />
            <div className="flex">                
                <div className="h-[550px] flex flex-col gap-4 p-2 border-r w-full">
                    <div className="mb-4">
                        <div className="text-gray-600 text-sm font-semibold flex items-end ml-1 gap-1">
                            <RiDashboard2Fill className="text-xl"/>
                            Dashboards
                        </div>
                        <MultiSelect 
                            options={props.cloudDashboards.map(d => {
                                return {
                                    label: d.title,
                                    value: d.id
                                }
                            })} 
                            selectedOptions={props.selectedDashboards.map(d => {
                                return {
                                    label: props.cloudDashboards.find(db => db.id === d.dashboard_id)?.title ?? "",
                                    value: d.dashboard_id
                                }
                            })}
                            onOptionSelect={(o) => {
                                if(!props.selectedDashboards.map(d => d.dashboard_id).includes(o.value)) {
                                    props.setSelectedDashboards([...props.selectedDashboards, {dashboard_id: o.value, include_table: true, include_widgets: true}])
                                }
                            }}
                            onOptionDeselect={(o) => {
                                props.setSelectedDashboards(props.selectedDashboards.filter(d => d.dashboard_id !== o.value))
                            }}
                            placeholder="Select Dashboards"
                        />
                    </div>
                    <div className="mb-4">
                        <div className="text-gray-600 text-sm font-semibold flex items-center gap-1 ml-1">
                            <FaFile className="text-lg" />
                            Insights
                        </div>
                        <MultiSelect 
                            options={props.cloudReports
                                .filter(r => r.is_public)
                                .map(r => {
                                return {
                                    label: r.name,
                                    value: r.report_id
                                }
                            })} 
                            selectedOptions={props.reportIds.map(rId => {
                                return {
                                    label: props.cloudReports.find(r => r.report_id === rId)?.name ?? "",
                                    value: rId
                                }
                            })}
                            onOptionSelect={(o) => {if(!props.reportIds.includes(o.value)) props.setReportIds([...props.reportIds, o.value])}}
                            onOptionDeselect={(o) => props.setReportIds(props.reportIds.filter(id => id !== o.value))}
                            placeholder="Select Insights"
                        />
                    </div>
                    <div className="flex items-end mb-4">
                        <CustomInput 
                            onChange={e => setInsightViewUrl(e.target.value)} 
                            value={insightViewUrl} 
                            label={
                                <span className="flex items-end text-sm font-semibold gap-1 ml-1">
                                    <GoGraph className="text-xl"/>
                                    Insight Views
                                </span>
                            } 
                            className="rounded-r-none border-r-0" 
                            placeholder="Insert insight view link"
                        />
                        <Button 
                            color="secondary" 
                            text="Add" 
                            disabled={!tmpInsightView} 
                            className="rounded-l-none border-2 border-l-0 border-r-gray-400 border-y-gray-400 h-9" 
                            onClick={() => {if(tmpInsightView) props.setInsightViews((p) => [...p, tmpInsightView]); setInsightViewUrl("")}}
                        />
                    </div>
                </div>
                <div className="p-2 flex flex-col gap-4 w-full h-[550px] overflow-y-auto">
                    {(props.selectedDashboards.length > 0 || dashboardsChanged) &&<div>
                         <div className="text-gray-600 text-xs p-1 border-b flex justify-between items-end">
                            <span>
                                <span className="font-semibold mr-1 text-sm">
                                    Selected Dashboards 
                                </span>
                                ({props.selectedDashboards.length})
                            </span>
                            {dashboardsChanged && 
                                <button
                                    className="px-2 py-0.5 border bg-gray-50 hover:bg-gray-100 cursor-pointer rounded-lg font-semibold"
                                    onClick={() => props.setSelectedDashboards(structuredClone(props.mailBundle.dashboards))}
                                >
                                    Reset
                                </button>
                            }
                        </div>
                        {props.selectedDashboards.length > 0 && props.selectedDashboards.map(d => {
                            return (
                                <div 
                                    key={d.dashboard_id} 
                                    className="p-1.5 border-b flex justify-between items-center w-full" 
                                >
                                    <span className="flex items-end gap-1">
                                        <RiDashboard2Fill className="text-dark-forest-800 text-lg"/>
                                        {props.cloudDashboards.find(db => db.id === d.dashboard_id)?.title}
                                    </span>    
                                    <div className="flex items-center gap-2 justify-center">
                                        {!d.include_table && <span className="text-gray-400 italic">Widgets only</span>}
                                        {!d.include_widgets && <span className="text-gray-400 italic">Table only</span>}
                                        <MenuDropdown color={"icon"} expand="left" icon={<HiDotsCircleHorizontal className="w-6 h-6 text-dark-forest-700 transition duration-300 transform hover:scale-110 cursor-pointer" aria-hidden="true" />}>
                                            <div className="px-1 py-1 ">
                                                <MenuItem>
                                                    <button
                                                        className={`${d.include_widgets ? 'bg-dark-forest-800 text-white' : 'text-gray-800'
                                                            } group flex items-center border-b justify-between w-full px-2 py-2 text-sm hover:bg-dark-forest-300 hover:text-gray-900`}
                                                        onClick={() => toggleDashboardSetting(d.dashboard_id, "include_widgets")}
                                                    >
                                                        Include Widgets
                                                        {d.include_widgets && <MdClose/>}
                                                    </button>
                                                </MenuItem>
                                                <MenuItem>
                                                    <button
                                                        className={`${d.include_table ? 'bg-dark-forest-800 text-white' : 'text-gray-800'
                                                            } group flex items-center justify-between w-full px-2 py-2 text-sm hover:bg-dark-forest-300 hover:text-gray-900`}
                                                        onClick={() => toggleDashboardSetting(d.dashboard_id, "include_table")}
                                                    >
                                                        Include Tables
                                                        {d.include_table && <MdClose/>}
                                                    </button>
                                                </MenuItem>
                                            </div>
                                        </MenuDropdown>
                                        <MdClose 
                                            className="cursor-pointer" 
                                            title="Remove dashboard" 
                                            onClick={() => props.setSelectedDashboards(props.selectedDashboards.filter(db => db.dashboard_id !== d.dashboard_id))}
                                            />
                                    </div>
                                </div>
                            )
                        })}
                    </div>}
                    {(props.reportIds.length > 0 || reportsChanged) && <div>
                         <div className="text-gray-600 text-xs p-1 border-b flex justify-between items-end">
                            <span>
                                <span className="font-semibold mr-1 text-sm">
                                    Selected Insights 
                                </span>
                                ({props.reportIds.length})
                            </span>
                            {reportsChanged && 
                                <button
                                    className="px-2 py-0.5 border bg-gray-50 hover:bg-gray-100 cursor-pointer rounded-lg font-semibold"
                                    onClick={() => props.setReportIds(structuredClone(props.mailBundle.report_ids))}
                                >
                                    Reset
                                </button>
                            }
                        </div>
                        {props.reportIds.length > 0 && props.reportIds.map(rId => {
                            return (
                                <div 
                                    key={rId} 
                                    className="p-1.5 border-b hover:bg-dark-forest-300 flex justify-between items-center cursor-pointer w-full" 
                                    onClick={() => props.setReportIds(props.reportIds.filter(id => id !== rId))}
                                >
                                    <span className="flex items-center gap-1">
                                        <FaFile className="text-dark-forest-800"/>
                                        {props.cloudReports.find(r => r.report_id === rId)?.name}
                                    </span>
                                    <MdClose/>
                                </div>
                            )
                        })}
                    </div>}
                    {(props.insightViews.length > 0 || insightViewsChanged) && <div>
                         <div className="text-gray-600 text-xs p-1 border-b flex justify-between items-end">
                            <span>
                                <span className="font-semibold mr-1 text-sm">
                                    Selected Insight Views 
                                </span>
                                ({props.insightViews.length})
                            </span>
                            {insightViewsChanged && 
                                <button
                                    className="px-2 py-0.5 border bg-gray-50 hover:bg-gray-100 cursor-pointer rounded-lg font-semibold"
                                    onClick={() => props.setInsightViews(structuredClone(props.mailBundle.insight_views))}
                                >
                                    Reset
                                </button>
                            }
                        </div>
                        {props.insightViews.length > 0 && props.insightViews.map(iv => {
                            return (<div 
                                key={iv.report_id} 
                                className="p-1.5 border-b hover:bg-dark-forest-300 flex justify-between items-center cursor-pointer w-full" 
                                onClick={() => props.setInsightViews(props.insightViews
                                    .filter(insightView => insightView.report_id !== iv.report_id || insightView.hash !== iv.hash || insightView.type !== iv.type)
                                )}
                            >
                                <span className="flex items-end gap-1">
                                    {iv.type === "trend" && <span className="border-r pr-1 mr-2 w-16"> 
                                        <GoGraph className="text-dark-forest-800 text-lg inline-block mr-1"/> 
                                        <span style={{fontSize: 11}}>Trend</span> 
                                    </span>}
                                    {iv.type === "matrix" && <span className="border-r pr-1 mr-2 w-16">
                                        <FaTh className="text-dark-forest-800 inline-block mr-1"/> 
                                        <span style={{fontSize: 11}}>Matrix</span> 
                                    </span>}
                                    {iv.type === "kpi" && <span className="border-r pr-1 mr-2 w-16">
                                        <FaWindowMaximize className="text-dark-forest-800 inline-block mr-1"/> 
                                        <span style={{fontSize: 11}}>KPI</span> 
                                    </span>}
                                    {iv.type === "sum" && <span className="border-r pr-1 mr-2 w-16">
                                        <FaTable className="text-dark-forest-800 inline-block mr-1"/> 
                                        <span style={{fontSize: 11}}>Sum</span> 
                                    </span>}
                                    {iv.type === "profit" && <span className="border-r pr-1 mr-2 w-16">
                                        <FaCalculator className="text-dark-forest-800 inline-block mr-1"/> 
                                        <span style={{fontSize: 11}}>Profit</span> 
                                    </span>}
                                    {props.cloudReports.find(r => r.report_id === iv.report_id)?.name}
                                </span>
                                <MdClose/>
                            </div>)
                        })}
                    </div>}
                </div>
            </div>
        </>
    )
}

interface ScheduleSelectProps {
    mailBundle: MailBundle;
    plans: Plan[];
    selectedPlan: Plan|null;
    setSelectedPlan: Dispatch<SetStateAction<Plan|null>>
}

function ScheduleSelect(props:ScheduleSelectProps){
    return <>
        <div className="h-[550px] overflow-y-auto">
            <div className="flex justify-evenly p-2 gap-2">
                <div className="flex flex-col gap-2 items-center">
                    <div className="text-lg w-full text-center font-semibold">Daily</div>    
                    {props.plans
                        .filter(p => p.interval.startsWith("D"))
                        .map(p => (
                            <div 
                                key={p.id} 
                                className={props.selectedPlan?.id === p.id ? "p-2 bg-dark-forest-800 text-white border rounded w-48 h-14 text-center " : "p-2 cursor-pointer hover:bg-dark-forest-300   border rounded w-48 h-14 text-center text-gray-500"}
                                onClick={() => props.setSelectedPlan(p)}
                            >
                                {p.description.substring(4)}
                            </div>
                        ))
                    }
                </div>
                <div className="flex flex-col gap-2 items-center">
                    <div className="text-lg w-full text-center font-semibold">Weekly</div>
                    {props.plans
                        .filter(p => p.interval.startsWith("W"))
                        .map(p => (
                            <div 
                                key={p.id} 
                                className={props.selectedPlan?.id === p.id ? "p-2 bg-dark-forest-800 text-white border rounded w-48 h-14 text-center " : "p-2 cursor-pointer hover:bg-dark-forest-300   border rounded w-48 h-14 text-center text-gray-500"}
                                onClick={() => props.setSelectedPlan(p)}
                            >
                                {p.description.substring(4)}
                            </div>
                        ))
                    }
                </div>
                <div className="flex flex-col gap-2 items-center">
                    <div className="text-lg w-full text-center font-semibold">Monthly</div>
                    {props.plans
                        .filter(p => p.interval.startsWith("M"))
                        .map(p => (
                            <div 
                                key={p.id} 
                                className={props.selectedPlan?.id === p.id ? "p-2 bg-dark-forest-800 text-white border rounded w-48 h-14 text-center " : "p-2 cursor-pointer hover:bg-dark-forest-300   border rounded w-48 h-14 text-center text-gray-500"}
                                onClick={() => props.setSelectedPlan(p)}
                            >
                                {p.description.substring(4)}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    </>
}

interface RecipientSelectProps{
    mailBundle: MailBundleWithRelations;
    users: CloudUser[];
    consultants: Consultant[];
    recipientIds: number[];
    setRecipientIds: Dispatch<SetStateAction<number[]>>;
    updateMailBundleState: (key:string, value:any) => void;
    selectedBuckets: string[];
    use_access_control: boolean;
    location_enabled: boolean;
}

function RecipientSelect(props:RecipientSelectProps){
    const recipients = useMemo(() => {
        let r = props.users.filter(u => !u.deleted).sort((a,b) => (a.firstname + a.lastname).localeCompare(b.firstname + b.lastname))
        if(props.use_access_control){
            return r.filter(r => props.selectedBuckets.every(b => r.bucket_access?.includes(b)))
        }
        return r
    }, [props.users, props.selectedBuckets])

    const recipientsChanged = useMemo(() => {
        if(props.mailBundle.user_ids.length !== props.recipientIds.length) return true
        return !props.mailBundle.user_ids.every(id => props.recipientIds.includes(id))
    }, [recipients, props.mailBundle])

    const [filteredRecipients, setFilteredRecipients] = useState<CloudUser[]>(recipients.sort((a,b) => (a.firstname + a.lastname).localeCompare(b.firstname + b.lastname)))
    const [filteredConsultants, setFilteredConsultants] = useState<Consultant[]>(props.consultants.sort((a,b) => (a.firstname + a.lastname).localeCompare(b.firstname + b.lastname)))
    const [filteredSelectedRecipients, setFilteredSelectedRecipients] = useState<CloudUser[]>(props.recipientIds
        .filter(id => recipients.some(r => r.id === id))
        .map(rId => recipients.find(r => r.id === rId)!)
        .sort((a,b) => (a.firstname + a.lastname).localeCompare(b.firstname + b.lastname)))
    const [filteredSelectedConsultants, setFilteredSelectedConsultants] = useState<Consultant[]>(props.recipientIds
        .filter(id => props.consultants.some(c => c.user_id === id))
        .map(rId => props.consultants.find(c => c.user_id === rId)!)
        .sort((a,b) => (a.firstname + a.lastname).localeCompare(b.firstname + b.lastname)))
    
    const [searchText, setSearchText] = useState<string>("");
    const [linkDisabled, setLinkDisabled] = useState<boolean>(false);

    const showSubscriptionWarning = useMemo(() => 
        props.mailBundle.self_subscription && (props.recipientIds.length > 1 || props.recipientIds.length < 1 || props.recipientIds.some(id => id !== props.mailBundle.owner_id))
    , [props.recipientIds])

    useEffect(() => {
        setLinkDisabled(!!props.recipientIds.find(id => isReportingServiceUser(recipients.find(r => r.id === id))))
    }, [props.recipientIds])

    useEffect(() => {
        props.updateMailBundleState("link", !linkDisabled);
    }, [linkDisabled])

    useEffect(() => {
        setFilteredRecipients(recipients
            .filter(r => (r.firstname + " " + r.lastname)
                .toLowerCase()
                .includes(searchText.toLowerCase()) || r.location?.toLowerCase().includes(searchText.toLowerCase())
            )
        )
        setFilteredConsultants(props.consultants
            .filter(c => (c.firstname + " " + c.lastname)
                .toLowerCase()
                .includes(searchText.toLowerCase())
            )
        )
        setFilteredSelectedRecipients(recipients
            .filter(r => props.recipientIds.includes(r.id ?? -1))
            .filter(r => (r.firstname + " " + r.lastname)
                .toLowerCase()
                .includes(searchText.toLowerCase()) || r.location?.toLowerCase().includes(searchText.toLowerCase())
            )
        )
        setFilteredSelectedConsultants(props.consultants
            .filter(c => props.recipientIds.includes(c.user_id))
            .filter(c => (c.firstname + " " + c.lastname)
                .toLowerCase()
                .includes(searchText.toLowerCase())
            )
        )
    }, [searchText, recipients])
    
    const isSelected = (r: CloudUser) => {
        return props.recipientIds.some(id => id === r.id)
    }
    const isSelectedConsultant = (c: Consultant) => {
        return props.recipientIds.some(id => c.user_id === id)
    }
    
    return <>
        <CustomInput onChange={e => setSearchText(e.target.value)} value={searchText} placeholder={props.location_enabled ? "Search by name, location..." : "Search by name..."} className="w-1/2"/>
        <div className="flex">
            <div className="p-2 border-r w-full h-[600px] flex flex-col">
                <div className="text-gray-600 text-xs border-b pb-2">
                    <div>
                        <span className="font-semibold mr-1 text-sm">
                            Recipients 
                        </span>
                        Showing&nbsp;
                    {filteredRecipients.length + " of " + recipients.length}
                </div>
                </div>
                <div className="overflow-y-auto h-full">
                    {filteredRecipients.map(r => {
                        if(!r.id) return null
                        const rId = r.id
                        return (
                            <div 
                                key={r.id}
                                onClick={() => { 
                                    !isSelected(r) ? props.setRecipientIds([...props.recipientIds, rId]) : props.setRecipientIds(props.recipientIds.filter(id => id !== rId))
                                }}
                                className={`${isSelected(r)? "bg-dark-forest-800 text-white" : "bg-white"} hover:bg-dark-forest-300 p-1.5 z-50 border-b flex items-center justify-between  cursor-pointer  focus:bg-gray-300`}
                            >
                                <span className="flex items-center gap-1">
                                    <span>{r.firstname + " " + r.lastname}</span>
                                    {isReportingServiceUser(r) ? <FaEnvelope className="text-dark-forest-800" /> : null}
                                </span>
                                {isSelected(r) && <MdClose className="text-white" />}
                            </div>
                        )
                    })}
                    {filteredConsultants.length > 0 && <div className="font-semibold p-1.5 border-b mt-4">Consultants</div>}
                    {filteredConsultants.map(c => {
                        if(!c.id) return null
                        const userId = c.user_id
                        return (
                            <div 
                                key={c.id}
                                onClick={() => { 
                                    !isSelectedConsultant(c) ? props.setRecipientIds([...props.recipientIds, userId]) : props.setRecipientIds(props.recipientIds.filter(id => id !== userId))
                                }}
                                className={`${isSelectedConsultant(c)? "bg-dark-forest-800 text-white" : "bg-white"} hover:bg-dark-forest-300 p-1.5 z-50 border-b flex items-center justify-between  cursor-pointer  focus:bg-gray-300`}
                            >
                                <span className="flex items-center gap-1">
                                    <span>{c.firstname + " " + c.lastname}</span>
                                </span>
                                {isSelectedConsultant(c) && <MdClose className="text-white" />}
                            </div>
                        )
                    })}
                </div>
                <div className="mt-2">
                    <div className="text-gray-600 text-xs mb-1">Include link</div>
                    <CustomSwitch enabled={props.mailBundle.link && !linkDisabled} disabled={linkDisabled} onChange={bool => {if(!linkDisabled) props.updateMailBundleState("link", bool)}} />
                    {linkDisabled && <div className="text-gray-600 text-xs mb-1">Link can not be included for reporting service users</div>}
                </div>
            </div>
            <div className="p-2 w-full h-[600px]">
                {props.recipientIds.length > 0 && <div className="text-gray-600 text-xs pb-2 border-b flex justify-between items-end">
                        <span>
                            <span className="font-semibold mr-1 text-sm">
                                Selected Recipients 
                            </span>
                            Showing&nbsp;
                            {filteredSelectedRecipients.length + " of " + props.recipientIds.filter(id => !props.consultants.some(c => c.user_id === id)).length}
                        </span>
                        {recipientsChanged && 
                            <button
                                className="px-2 py-0.5 border bg-gray-50 hover:bg-gray-100 cursor-pointer rounded-lg font-semibold"
                                onClick={() => props.setRecipientIds(structuredClone(props.mailBundle.user_ids))}
                            >
                                Reset
                            </button>
                        }
                    </div>
                }    
                <div className="h-[475px] overflow-y-auto">
                    {props.recipientIds.length > 0 && filteredSelectedRecipients
                        .map(recipient => {
                            return (
                                <div 
                                    key={recipient.id} 
                                    className="p-1.5 border-b  hover:bg-dark-forest-300 flex justify-between items-center cursor-pointer w-full" 
                                    onClick={() => props.setRecipientIds(props.recipientIds.filter(id => id !== recipient.id))}
                                >
                                    <span className="flex items-center gap-1">
                                        <span>
                                            {recipient?.firstname + " " + recipient?.lastname}
                                        </span>
                                        {isReportingServiceUser(recipient) ? <FaEnvelope className="text-dark-forest-800" /> : null}
                                    </span>
                                    <MdClose/>
                                </div>
                            )})
                    }
                    {props.recipientIds.length > 0 && filteredSelectedConsultants.length > 0 && <div className="text-gray-600 text-xs pb-2 border-b flex justify-between items-end mt-4">
                        <span>
                            <span className="font-semibold mr-1 text-sm">
                                Selected Consultant Recipients 
                            </span>
                            Showing&nbsp;
                            {filteredSelectedConsultants.length + " of " + props.recipientIds.filter(id => props.consultants.some(c => c.user_id === id)).length}
                        </span> 
                    </div>}
                    {props.recipientIds.length > 0 && filteredSelectedConsultants
                        .map(consultant => {
                            return (
                                <div 
                                    key={consultant.id} 
                                    className="p-1.5 border-b  hover:bg-dark-forest-300 flex justify-between items-center cursor-pointer w-full" 
                                    onClick={() => props.setRecipientIds(props.recipientIds.filter(id => id !== consultant.user_id))}
                                >
                                    <span className="flex items-center gap-1">
                                        <span>
                                            {consultant?.firstname + " " + consultant?.lastname}
                                        </span>
                                    </span>
                                    <MdClose/>
                                </div>
                            )})
                    }
                </div>
                {showSubscriptionWarning && 
                    <AlertBanner
                        text={(
                            <div className="flex flex-col items-start">
                                Adding or changing recipients for this subscription, will make it a bundle. 
                                <Button color="default" text="Reset" className="bg-white" onClick={() => props.setRecipientIds([props.mailBundle.owner_id])}/>
                            </div>)}
                        type="warning"
                        title={""}
                    />
                }
            </div>
        </div>
    </>
}

interface PdfOptionsProps{
    mailBundle: MailBundle;
    updateMailBundleState: (key:string, value:any) => void;
}

function PdfOptions(props:PdfOptionsProps){
    return (
        <>
            <div className="flex justify-evenly items-start min-h-[500px] mt-32">
                <div className="font-semibold w-48">
                    Merged PDF
                    <div 
                        className={`${props.mailBundle.combined_pdf ? "bg-dark-forest-800 text-white" : "hover:bg-dark-forest-300 cursor-pointer"} select-none border rounded-xl w-48 h-48 flex justify-center items-center mt-1`}
                        onClick={() => props.updateMailBundleState("combined_pdf", true)}
                    >
                        <IoDocumentOutline className="text-8xl"/>
                    </div>
                    <span className={`${props.mailBundle.combined_pdf ? "" : "font-normal"} italic`}>
                        Recipients will receive a single PDF containing all items
                    </span>
                </div>
                <div className="font-semibold w-48">
                    Seperate PDFs
                    <div 
                        className={`${!props.mailBundle.combined_pdf ? "bg-dark-forest-800 text-white" : "hover:bg-dark-forest-300 cursor-pointer"} select-none border rounded-xl w-48 h-48 flex justify-center items-center mt-1`}
                        onClick={() => props.updateMailBundleState("combined_pdf", false)}
                    >
                        <IoDocumentsOutline className="text-8xl"/>
                    </div>
                    <span className={`${!props.mailBundle.combined_pdf ? "" : "font-normal"} italic`}>
                        Recipients will receive a PDF for each item
                    </span>
                </div>
            </div>
        </>
    )
}

interface SaveButtonProps{
    validateMailBundle: () => boolean;
    addNewMailBundle: (activate:boolean, send?:boolean) => void;
    activateOnSave: boolean
}

function SaveButton(props:SaveButtonProps){
    return (
        <div className="flex items-center rounded-lg shadow-sm bg-dark-forest-700 h-[38px] relative">
            <Button className="rounded-l-lg ronded-r-none" text={props.activateOnSave ?"Save & Activate" : "Save"} onClick={() => props.addNewMailBundle(props.activateOnSave)} color="primary" disabled={!props.validateMailBundle()}/>
        </div>
    )
}

export default AddMailBundle