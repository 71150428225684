import React, { useMemo, useState } from "react";
import { FaTh, FaTimes } from "react-icons/fa";
import { HiChevronLeft, HiChevronRight, HiOutlineMinus } from "react-icons/hi";
import { BucketColumnType, BucketTemplate, CompareType, ReportFilter, ReportTemplate } from "../../types/template_types";
import SearchableMenu from "../SearchableMenu";
import ReportTemplateSlicerEditor from "./ReportTemplateSlicerEditor";

interface ReportTemplateSlicerSetupProps {
    tmpReport: ReportTemplate,
    setTmpReport: React.Dispatch<React.SetStateAction<ReportTemplate | undefined>>,
    bucket: BucketTemplate,
}

const ReportTemplateSlicerSetup = ({tmpReport, setTmpReport, bucket, ...props}: ReportTemplateSlicerSetupProps) => {

    const [slicerToEdit, setSlicerToEdit] = useState<ReportFilter>()
    const [showEditSlicer, setShowEditSlicer] = useState(false)

    const bucketTypes = useMemo(() => {
        let map = new Map<string, BucketColumnType>()
        bucket.columns.forEach(c => map.set(c.name, c.type))
        return map
    }, [bucket])

    const addFixedSlicer = (column: string) => {
        let tmp = structuredClone(tmpReport)
        tmp.fixed_slicers.push(column)
        setTmpReport(tmp)
    }

    const moveFixedSlicer = (index: number, direction: "left" | "right") => {
        if (tmpReport === undefined) return
        if ((direction === "left" && index === 0) || (direction === "right" && index === tmpReport.fixed_slicers.length - 1)) return
        let tmp = structuredClone(tmpReport)
        const newIndex = direction === "left" ? index - 1 : index + 1
        const column = tmp.fixed_slicers[index]
        tmp.fixed_slicers.splice(index, 1)
        tmp.fixed_slicers.splice(newIndex, 0, column)
        setTmpReport(tmp)
    }

    const removeFixedSlicer = (index: number) => {
        let tmp = structuredClone(tmpReport)
        tmp.fixed_slicers.splice(index, 1)
        setTmpReport(tmp)
    }

    const addFilter = (column: string) => {
        let tmp = structuredClone(tmpReport)
        let filter = {column: column, compare_type: CompareType.EQ, target_values: [], is_column: false}
        tmp.filters.push(filter)
        setTmpReport(tmp)
        setSlicerToEdit(filter)
        setShowEditSlicer(true)
    }

    const onSaveSlicer = (slicer: ReportFilter) => {
        let tmp = structuredClone(tmpReport)
        const index = tmpReport.filters.findIndex(x => x.column === slicer.column)
        if (index !== -1) {
            tmp.filters[index] = slicer
        } else {
            tmp.filters.push(slicer)
        }
        tmp.filters = tmp.filters.filter(f => f.target_values.length > 0)
        setTmpReport(tmp)
        setSlicerToEdit(undefined)
        setShowEditSlicer(false)
    }

    const onEditSlicer = (slicer: ReportFilter) => {
        setSlicerToEdit(slicer)
        setShowEditSlicer(true)
    }

    const onRemoveSlicer = (index: number) => {
        let tmp = structuredClone(tmpReport)
        tmp.filters.splice(index)
        setTmpReport(tmp)
    }

    const onCancelEditSlicer = () => {
        let tmp = structuredClone(tmpReport)
        const index = tmpReport.filters.findIndex(x => x.column === slicerToEdit?.column)
        if (index === -1) return
        if (slicerToEdit?.target_values.length === 0) {
            tmp.filters.splice(index, 1)
        }
        setTmpReport(tmp)
        setSlicerToEdit(undefined)
        setShowEditSlicer(false)
    }
    console.log(bucketTypes);
    
    return <div className="flex flex-col gap-3">
        <h2 className='border-b border-slate-200 mt-5 text-gray-500 text-lg'>Fixed slicer setup</h2>
        <SearchableMenu 
            label="Add fixed slicer"
            options={tmpReport.columns
                .filter(col => (bucketTypes.get(col) === BucketColumnType.Text || bucketTypes.get(col) === BucketColumnType.Categorization))
                .filter(v => !tmpReport.fixed_slicers.some(x => x === v)).sort().map((v) => ({label: v, onClick: () => {addFixedSlicer(v)}, icon: v === bucket.categorization_name ? <FaTh className="ml-1" /> : null}))} 
        />
        <div className="flex gap-1">
            {
                tmpReport.fixed_slicers.map((v, i) => {
                    return <div key={i} className="bg-white border border-[hsl(0,0%,70%)] p-2 flex items-center min-w-[200px] justify-between">
                        <div className="flex items-center">
                            <div className="flex items-center border-r border-[hsl(0,0%,70%)] pr-1 mr-2">
                                <HiChevronLeft onClick={() => moveFixedSlicer(i, "left")} className={`w-5 h-5 ${i === 0 ? 'fill-gray-300' : 'fill-gray-400 cursor-pointer hover:fill-black'}`} />
                                <HiChevronRight onClick={() => moveFixedSlicer(i, "right")} className={`w-5 h-5 ${i === tmpReport.fixed_slicers.length - 1 ? 'fill-gray-300' : 'fill-gray-400 cursor-pointer hover:fill-black'}`} />
                            </div>
                            {v}
                        </div>
                        <button onClick={() => removeFixedSlicer(i)} className="ml-2" title="Remove fixed slicer" ><HiOutlineMinus className="stroke-gray-400 hover:stroke-red-500"/></button>
                    </div>
                })
            }
        </div>

        <h2 className='border-b border-slate-200 mt-5 text-gray-500 text-lg'>Slicer setup</h2>
        <SearchableMenu 
            label="Add slicer"
            options={tmpReport.columns.filter(v => !tmpReport.filters.some(x => x.column === v)).sort().map((v) => ({label: v, onClick: () => {addFilter(v)}, icon: v === bucket.categorization_name ? <FaTh className="ml-1" /> : null}))} 
        />
        <div className="flex gap-2">
            {
                tmpReport.filters.map((f, i) => {
                    return <button key={i} onClick={() => onEditSlicer(f)} className="flex justify-between items-center bg-white rounded-md shadow-md p-3 w-[300px] hover:bg-neutral-50">
                        <span className="truncate">{f.column} - {f.target_values.length}</span>
                        <div onClick={(e) => {e.stopPropagation(); onRemoveSlicer(i)}} className="text-gray-400 hover:text-black cursor-pointer"><FaTimes /></div>
                    </button>
                })
            }
        </div>
        <ReportTemplateSlicerEditor bucket={bucket} slicer={slicerToEdit} tmpReport={tmpReport} saveSlicer={onSaveSlicer} onClose={onCancelEditSlicer} show={showEditSlicer} slicers={tmpReport.filters} />
    </div>
}

export default ReportTemplateSlicerSetup
